<template>
  <div class="table">
    <el-table
      ref="multipleTable"
      :data="tableData"
      :highlight-current-row="true"
      tooltip-effect="dark"
      style="width: 100%; color: #858585"
      @selection-change="selectRow"
      :header-cell-style="tableHeaderCellStyle"
    >
      <el-table-column type="selection" fill="#00CCCC" fixed="left">
      </el-table-column>
      <!-- 表头开始 -->
      <el-table-column prop="ruleName" label="规则名称" align="center">
      </el-table-column>
      <el-table-column prop="deptName" label="适用科室" align="center">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            @change="swith(scope.row)"
            active-color="#00cccc"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="weekDay" label="适用星期" align="center">
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template slot-scope="scope">
          <span
            style="color: #00cccc; cursor: pointer"
            @click="clickRow(scope.row)"
            >查看详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page">
      <!-- left -->
      <span class="pageLeft"
        >共 {{ count }} 条记录 第 {{ currentPage }} / {{ tatalPage }}页</span
      >
      <!-- right -->
      <el-pagination
        @size-change="SizeChange"
        background
        @current-change="CurrentChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="onlyPage"
        :pager-count="5"
        layout="sizes, prev, pager, next, jumper"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRulesList, updateRules, getHospitalList } from '@/api/order.js';
export default {
  data() {
    return {
      tableData: [], //表格数据
      multipleSelection: [], // tableCheckboxValue
      currentPage: 1, // 当前页面,默认1
      tatalPage: 0, //总页数,为加载时先为0，页面首次加载后去请求即可
      count: 0, //总的条数记录
      onlyPage: 10, // 当前页面可以展示多少条数据,默认10
      hospitalId: null
    };
  },

  mounted() {
    getHospitalList({
      //当前该账户下以默认绑定有的
    }).then((res) => {
      if (res.data.status == 200) {
        this.hospitalId = res.data.data.id;
        this.getData(1, 10, null);
      }
    });
  },

  watch: {
    multipleSelection: {
      handler(pre) {
        var tem = pre;
        if (tem.length == 0) {
          this.$parent.isable = true;
        }
        if (tem.length >= 1) {
          tem.forEach((a, b) => {
            if (a.status == false) {
              this.$parent.isable = true;
              this.$message({
                message: '当前状态不能选',
                type: 'warning'
              });
            } else {
              this.$parent.isable = false;
              this.$parent.tableSelect = tem;
            }
          });
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    //表头样式回调
    tableHeaderCellStyle() {
      return 'color:#fff;background-color:#00CCCC';
    },
    // getData
    getData(currentPage, onlyPage, name) {
      const rLoading = this.openLoading(); // 开始loading
      getRulesList({
        currentPage: currentPage,
        pageSize: onlyPage,
        ruleName: name,
        orgId: this.hospitalId
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.tableData = res.data.data.records;
            this.tatalPage = parseInt(res.data.data.size);
            this.count = parseInt(res.data.data.total);
            rLoading.close(); // 关闭loading
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    // 复选框选项
    selectRow(val) {
      //console.log(this.multipleSelection);
      this.multipleSelection = val;
    },
    // 分页相关
    SizeChange(val) {
      this.onlyPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(this.currentPage, this.onlyPage, this.$parent.ruleName);
    },
    CurrentChange(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(this.currentPage, this.onlyPage, this.$parent.ruleName);
    },
    prevClick(val) {
      // 上一页
      // console.log("上一页的页数是",val);
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(this.currentPage, this.onlyPage, this.$parent.ruleName);
    },
    nextClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(this.currentPage, this.onlyPage, this.$parent.ruleName);
    },

    //开关
    swith(e) {
      var status = e.status;
      var id = e.id;
      this.$parent.itemId = id;
      if (status == false) {
        this.$parent.openActive = true;
      } else {
        updateRules({
          id: id,
          status: 'true'
        }).then((res) => {
          if (res.status != 200) {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    //click
    clickRow(e) {
      //id  | wid
      console.log('id', e.id);
      this.$router.push({
        path: '/viewRule',
        query: {
          id: e.id
        }
      });
    }
  }
};
</script>
<style scoped>
.table {
  margin-top: 0.1563rem;
}
.el-button {
  color: #00a3a3 !important;
}
.action {
  display: flex;
  flex-direction: column;
}
.actionP {
  cursor: pointer;
  color: #00cccc;
  margin: 0 !important;
}
.resoult {
  text-align: left;
}
.page {
  display: flex;
  margin-top: 0.3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft {
  color: #858585;
  font-size: 0.1094rem;
  line-height: 0.1875rem;
}
.el-pagination {
  color: #858585 !important;
  font-size: 0.1094rem !important;
  line-height: 0.1875rem !important;
}
</style>
