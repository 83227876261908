<template>
  <div class="checkTask">
    <!-- top -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>排班管理</el-breadcrumb-item>
      <el-breadcrumb-item>排班规则</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tableBox">
      <!-- blue_title -->
      <div class="blueTitle" style="margin-left: 12px">排班规则</div>
      <div class="tableTop">
        <div class="table_l">
          <div class="add" @click="addRlus">新建规则</div>

          <div class="addn" style="margin-left: 20px" v-if="isable">
            设置生效时间
          </div>
          <div
            class="add"
            style="margin-left: 20px"
            v-if="!isable"
            @click="setTime"
          >
            设置生效时间
          </div>
        </div>
        <div class="table_r">
          <div class="result">
            <el-select
              v-model="roomId"
              @change="setHosk"
              style="width: 152px !important"
            >
              <el-option
                v-for="item in optionsk"
                :key="item.value"
                :label="item.deptName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search">
            <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="search"
              v-model="seachAll"
              class="elSerch"
            >
              <template
                slot="append"
                style="background-color: #00cccc; cursor: pointer"
                >搜索</template
              >
            </el-input>
          </div>
        </div>
      </div>
      <ScheduTable ref="uetable" />
    </div>
    <!-- 禁用弹窗 -->
    <el-dialog
      :visible.sync="openActive"
      :show-close="false"
      width="432px"
      height="168px"
      center
    >
      <span>禁用后该科室的排版规则将会下周开始失效，是否确认禁用？</span>
      <span slot="footer" class="dialog-footer">
        <div class="footer1" @click="openCancel">取 消</div>
        <div class="footer2" @click="openSure">禁用</div>
      </span>
    </el-dialog>
    <!-- 设置时间弹框 -->
    <el-dialog title="提示" :visible.sync="isdate" width="30%">
      <el-date-picker
        v-model="date"
        value-format="yyyy-MM-dd"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceldate">取 消</el-button>
        <el-button type="primary" @click="suredate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ScheduTable from '@/components/table/scheduTable.vue';
import {
  updateRules,
  activeTime,
  getHospitalList,
  getRoomList
} from '@/api/order.js';
export default {
  components: {
    ScheduTable
  },
  data() {
    return {
      isable: true, //选择状态
      tableSelect: null,
      isdate: false, //选择时间弹框
      date: null, //选择的日期
      seachAll: null,
      openActive: false, //开关状态
      itemId: null,
      ruleName: null, //科室名称
      //评估结果选择
      hospitalId: null,
      optionsk: null,
      roomId: null,
      resultOption: [],
      evaValue: null, //评估结果
      submitOption: '选择查询科室'
    };
  },
  mounted() {
    this.getHoList();
  },

  methods: {
    //新增规则
    addRlus() {
      this.$router.push({
        path: '/addRules'
      });
    },
    search(e) {
      this.ruleName = e;
      this.$refs.uetable.getData(1, 10, e);
    },
    //评估结果选择
    selectResult(e) {
      //console.log(e);
      this.evaValue = e;
      // this.$refs.uetable.getData(1,10);
    },
    //取消禁用
    openCancel() {
      this.openActive = false;
      this.$refs.uetable.getData(1, 10);
    },
    //确认禁用
    openSure() {
      updateRules({
        id: this.itemId,
        status: 'false'
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          });
          this.$refs.uetable.getData(1, 10);
          this.openActive = false;
        } else {
          this.$message.error(res.data.message);
          this.$refs.uetable.getData(1, 10);
        }
      });
    },
    //设置生效时间
    canceldate() {
      this.isdate = false;
    },

    suredate() {
      var ids = [];
      if (this.tableSelect != null) {
        this.tableSelect.forEach((item, index) => {
          console.log(item);
          if (item.status == true) {
            ids.push(item.id);
          }
        });
      }
      activeTime({
        createDate: this.date,
        roleIds: ids
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            message: '设置成功！',
            type: 'success',
            duration: 1800,
            onClose: () => {
              this.isdate = false;
              this.$refs.uetable.getData(1, 10);
            }
          });
        } else {
          this.$message.error(res.data.message);
          this.isdate = false;
          this.$refs.uetable.getData(1, 10);
        }
      });
    },
    setTime() {
      this.isdate = true;
    },

    //默认查询当前医院
    //查询医院列表
    getHoList() {
      getHospitalList({
        //当前该账户下以默认绑定有的
      }).then((res) => {
        if (res.data.status == 200) {
          this.hospitalId = res.data.data.id;
          //开始查询科室列表
          getRoomList({
            orgId: this.hospitalId
          }).then((res) => {
            if (res.data.status == 200) {
              this.optionsk = res.data.data.records;
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //选择科室id
    setHosk(e) {
      console.log('选择科室', e);
      this.roomId = e;
    }
  }
};
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 2.4 !important;
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

/deep/.dialog-footer{
    display: block !important;
    width: 284px !important;
    height: 32px !important;
    justify-content: space-between;
    margin:  0 auto !important;
    display: flex !important;
}
/deep/.footer1{
    width: 120px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius:4px;
    color: #00cccc;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #00cccc;
}
/deep/.footer2{
    width: 120px;
    height: 32px;
    background-color: #00cccc;
    border-radius:4px;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
}
//component style
.blueTitle
  font-size: 18px
  color: #353535
  display: flex
  align-items: center
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC
.seachText
    font-size: 14px
    color: #AEAEAE
//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            margin-top: 20px
            margin-bottom: 30px
            align-items: center
            justify-content: space-between
            .table_l
                display: flex
                .add
                    width: 140px
                    height: 32px
                    background-color: #00cccc
                    text-align: center
                    border-radius:2px
                    font-size: 14px
                    color: #fff
                    line-height: 32px
                    cursor pointer
                .addn
                    width: 140px
                    height: 32px
                    background-color:#F4F4F4
                    color: #AEAEAE
                    text-align: center
                    border-radius:2px
                    font-size: 14px
                    line-height: 32px
                    cursor:not-allowed


            .table_r
                display: flex
                align-items: center
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff
</style>
